import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { firstValueFrom, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  editingPerson: any | undefined;
  baseUrl: string = environment.APIURL;

  constructor(private http: HttpClient) {}

  getWaiverAndInfo(eventId: number): Observable<unknown> {
    const path = `api/events/${eventId}/editor/waiver-and-info`;
    return this.http.get(`${this.baseUrl}${path}`);
  }

  putWaiverAndInfo(
    eventId: number,
    name: string,
    info: string,
    waiver: string
  ): Observable<unknown> {
    const path = `api/events/${eventId}/editor/waiver-and-info`;
    const data = {
      id: eventId,
      name: name,
      info: info,
      waiver: waiver,
    };
    return this.http.put(`${this.baseUrl}${path}`, data);
  }

  getSports() {
    const path = `api/leagues/sports/filter`;
    const data = {
      page: 0,
      size: 100,
      filter: {
        eventId: null,
        leagueIds: [40],
      },
    };
    return this.http.post(`${this.baseUrl}${path}`, data);
  }

  getClubs() {
    const path = `api/clubs/filter`;
    const data = {
      page: 0,
      size: 100,
      filter: {
        eventId: null,
        leagueIds: [40],
      },
    };
    return this.http.post(`${this.baseUrl}${path}`, data);
  }

  getRegistrationConfig(countryId: number, sportId: number, birthDate: string) {
    const path = `api/event/account/persons/334091/registration/check-config`;
    const data = {
      birthDate: birthDate,
      countryId: countryId,
      sportId: sportId,
    };
    return this.http.post(`${this.baseUrl}${path}`, data);
  }

  getPerson(updateId: number): Observable<unknown> {
    const path = `api/event/account/persons/filter`;
    const data = {
      filter: {
        id: updateId,
        excludeIds: [0],
      },
      offset: 0,
    };

    return this.http.post(`${this.baseUrl}${path}`, data);
  }

  getPersons(search: string, page: number, size: number, orders?: any) {
    const path = `api/event/account/persons/filter`;

    const data: {
      page: number;
      size: number;
      filter: { excludeIds: number[]; name: string };
      offset: number;
      sort?: any;
    } = {
      page,
      size,
      filter: {
        excludeIds: [0],
        name: search,
      },
      offset: 0,
    };

    if (orders !== undefined) {
      data.sort = orders;
    }

    return this.http.post(`${this.baseUrl}${path}`, data);
  }

  async getSelfPerson() {
    let page = 0;
    let pageSize = 5;
    let totalPages = 1;
    try {
      while (page <= totalPages) {
        const response: any = await firstValueFrom(
          this.getPersons("", page, pageSize)
        );
        if (response.items) {
          for (const i of response.items) {
            if (i.relation && i.relation === "SELF_PERSON") {
              return i;
            }
          }
        }
        page++;
        if (!response.totalPages) {
          return null;
        }
        totalPages = response.totalPages;
      }
      return null;
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  addPerson(data: {
    email: string;
    firstName: string;
    lastName: string;
    middleName?: string;
    gender: "MALE" | "FEMALE";
    photoUrl?: string;
    birthDate: string;
    country: any;
    address: string;
    zipCode: string;
    phoneNumber?: string;
    clubId?: number;
    relation: "SELF_PERSON" | "MINOR";
    // externalId: "string",
    // integrationType: "NONE",
    // isSelected: true,
  }) {
    const path = `api/event/account/persons`;
    return this.http.post(`${this.baseUrl}${path}`, data);
  }

  uploadImage(image: any, id: any): Observable<unknown> {
    const file = new File([image.blob], id + ".png", {
      type: image.blob.type,
    });

    const formData = new FormData();
    formData.append("file", file);

    const path = `api/event/account/persons/${id}/upload-picture`;
    return this.http.post(`${this.baseUrl}${path}`, formData);
  }

  updatePerson(data: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    middleName?: string;
    gender: "MALE" | "FEMALE";
    photoUrl?: string;
    birthDate: string;
    country: any;
    address: string;
    zipCode: string;
    phoneNumber?: string;
    clubId?: number;
    relation: "SELF_PERSON" | "MINOR";
    // externalId: "string",
    // integrationType: "NONE",
    // isSelected: true,
  }) {
    const path = `api/event/account/persons`;
    return this.http.put(`${this.baseUrl}${path}`, data);
  }

  getAllowedOrderItems(eventId: number): Observable<unknown> {
    const path = `api/orders/${eventId}/allowed-order-items`;
    return this.http.get(`${this.baseUrl}${path}`);
  }

  getOrder(eventId: number): Observable<unknown> {
    const path = `api/orders/${eventId}/self/active`;
    return this.http.get(`${this.baseUrl}${path}`);
  }

  insertOrderItem(eventId: number, personId: number, coverage: string) {
    const path = `api/orders/${eventId}/items`;
    const data = {
      itemType: "MEMBERSHIPFEE",
      personId: personId,
      person: { id: personId },
      coverage: coverage,
    };

    return this.http.post(`${this.baseUrl}${path}`, data);
  }

  removeOrderItem(eventId: number, itemId: number, personId: number) {
    const path = `api/orders/${eventId}/items`;
    const params = {
      personId: personId,
      person: { id: personId },
      id: itemId,
    };

    return this.http.delete(`${this.baseUrl}${path}`, { body: params });
  }
}
