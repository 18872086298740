import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { LocalizationModule } from "src/app/components/localization";
import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { BottomBannerComponent } from "src/app/components/bottom-banner/bottom-banner.component";
import { Select, Store } from "@ngxs/store";
import { EventsService } from "src/app/shared/constants/events.service";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { UserState } from "src/app/store/user/user.store";
import { firstValueFrom, map, Observable, skip, Subscription } from "rxjs";
import { User } from "src/app/shared/models/user.model";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { RegistrationAthleteComponent } from "../registration-athlete/registration-athlete.component";
import { ApiService } from "src/app/shared/constants/api.service";
import { CustomMaterialInputComponent } from "src/app/components/custom-material-input/custom-material-input.component";
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { NgxPaginationModule } from "ngx-pagination";
import { ToastrService } from "ngx-toastr";
import { SafePipe } from "../../shared/pipes/safe.pipe";
import { SafeTypes } from "../../shared/constants/safe-types.enum";
import { getAge, getName } from "src/app/utils/membership-utils";

@Component({
  selector: "app-roster-shopping-cart",
  templateUrl: "./roster-shopping-cart.component.html",
  standalone: true,
  imports: [
    LocalizationModule,
    NgFor,
    NgIf,
    NgClass,
    SafePipe,
    RouterModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    CommonModule,
    CustomMaterialInputComponent,
    NgxPaginationModule,
  ],
  styleUrls: ["./roster-shopping-cart.component.scss"],
})
export class RosterShoppingCartComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Select(UserState.authUser)
  public authUser$!: Observable<User>;

  @Select(UserState.isAdmin)
  isAdmin$!: Observable<boolean>;

  user: User | undefined | null;

  subscriptions: Subscription[] = [];
  pageSize = 1000;
  pageIndex = 0;
  membersAmount!: number;
  public membersData: any;
  selectedCount: number = 0;
  eventId: number = 0;

  terms = `Membership in the AAU is a privilege granted by the AAU. It is not a right. The AAU at its sole discretion reserves the right to accept or reject any applicant(s) for membership.<br><br>Membership in any category may be granted only after an application is submitted and approved. By submitting an application, the applicant agrees to comply with all the provisions of the <u>AAU Code, including its constitution, bylaws, policies, procedures, regulations, and rules*</u>.<br><br> I certify that I have the athlete's parent's or guardian's consent for the athlete to become an AAU Member.<br><br>* I accept all terms and conditions for this AAU membership application as laid out by the AAU code book (available here) and this application.<br><br>* I hereby certify that all information I have provided is accurate, my name (below) is correct, and I am authorized to apply for membership for the youths in this application.<br><br>* I understand that there are no refunds issued for cancellations.<br><br>Membership must always be paid and member number issued prior to participation in any activity (including practices and try-outs)`;
  agreed = false;

  signatureForm!: FormGroup;

  athletes: {
    name: string;
    gender: string;
    age: number | null;
    extendedBenefit: boolean;
    price: number;
  }[] = [];
  totalAmount: number = 0;

  submitted = false;
  data: any;

  constructor(
    private api: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.signatureForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
    });
  }

  updateForm(formInput: string, value: string) {
    const control = this.signatureForm.get(formInput);
    if (control) {
      control.setValue(value);
      control.markAsTouched();
    }
  }

  updateFormWithEvent(inputValue: string, event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.updateForm(inputValue, inputElement.value);
  }

  async ngAfterViewInit(): Promise<void> {
    this.subscriptions.push(
      this.authUser$.subscribe(async (user: any) => {
        if (user) {
          this.user = user;

          this.filterMembers("", this.pageIndex, this.pageSize);
          // const isAdmin = this.store.selectSnapshot(UserState.isAdmin);
          // if (!isAdmin) {
          //   this.router.navigateByUrl("/");
          // }
        } else {
          // this.router.navigateByUrl("/");
        }
      })
    );
    this.eventId = +this.route.snapshot.params["eventId"];
    this.data = await firstValueFrom(this.api.getOrder(this.eventId));

    this.loadOrderItems();
  }

  async loadOrderItems() {
    let orderItemsList = this.data.orderItemList;
    if (!orderItemsList) {
      console.error("No items in order");
      return;
    }

    this.athletes = [];
    orderItemsList.forEach((element: any) => {
      this.athletes.push({
        name: element.person.firstName + " " + element.person.lastName,
        gender: element.person.gender,
        age: getAge({ birthDate: element.person.birthDate }),
        extendedBenefit: element.price === 25 ? true : false,
        price: element.price,
      });
    });
    this.totalAmount = this.data.totalPrice;
  }

  async filterMembers(search: string, pageIndex: number, pageSize: number) {
    try {
      const data = await firstValueFrom(
        this.api.getPersons(search, pageIndex, pageSize)
      );
      this.membersData = data;
    } catch (e) {
      console.error(e);
    }
  }

  ngOnInit(): void {
    this.eventId = +this.route.snapshot.params["eventId"];
    if (!this.eventId) {
      // TODO: shoudl I go back?
    }
    // this.filterMembers(this.searchString);
    // this.fetchPaidOrders();
    // this.fetchActiveOrders();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      try {
        s.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    }
  }

  expand(athlete: any) {
    if (athlete.expanded) {
      athlete.expanded = false;
    } else {
      athlete.expanded = true;
    }
  }

  onEdit(athlete: any) {
    this.api.editingPerson = athlete;
    this.router.navigateByUrl(
      `roster/${this.eventId}/edit-athlete/${athlete.id}`
    );
  }

  copyToClipboard(value: string) {
    navigator.clipboard.writeText(value).then(() => {
      this.toastr.success("Copied to clipboard!");
    });
  }

  addAthlete() {
    this.router.navigateByUrl(`roster/${this.eventId}/new-athlete`);
  }

  goToMembershipsStep() {
    if (this.data?.paymentFormUrl) {
      window.location.href = this.data?.paymentFormUrl;
    }
  }

  getName(athlete: any): string {
    return getName(athlete);
  }

   getAge(athlete: any): number | null {
     return getAge(athlete);
   }

  getCoverageType(extended: boolean) {
    if (extended) {
      return "ROSTER_EXTENDED_COVERAGE";
    } else {
      return "ROSTER_STANDARD_COVERAGE";
    }
  }
}
