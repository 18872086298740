<div class="c-pad custom-component-theme">
  <div class="header">
    <div class="left-header">
      <div class="title-section">
        <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.125 0.5C14.1406 0.5 15 1.35938 15 2.375V18.625C15 19.6797 14.1406 20.5 13.125 20.5H1.875C0.820312 20.5 0 19.6797 0 18.625V2.375C0 1.35938 0.820312 0.5 1.875 0.5H13.125ZM5.625 1.75C5.27344 1.75 5 2.0625 5 2.375C5 2.72656 5.27344 3 5.625 3H9.375C9.6875 3 10 2.72656 10 2.375C10 2.0625 9.6875 1.75 9.375 1.75H5.625ZM7.5 6.75C6.09375 6.75 5 7.88281 5 9.25C5 10.6562 6.09375 11.75 7.5 11.75C8.86719 11.75 10 10.6562 10 9.25C10 7.88281 8.86719 6.75 7.5 6.75ZM11.875 16.0078V15.2656C11.875 14.0156 10.6641 13 9.21875 13H9.02344C8.55469 13.2344 8.04688 13.3125 7.5 13.3125C6.91406 13.3125 6.40625 13.2344 5.9375 13H5.74219C4.29688 13 3.125 14.0156 3.125 15.2656V16.0078C3.125 16.4375 3.51562 16.75 3.98438 16.75H10.9766C11.4844 16.75 11.875 16.4375 11.875 16.0078Z"
            fill="#0E2A8D" />
        </svg>
        <span class="title">{{ "NAV_ROSTER_STEP_TWO" | transloco }}</span>
      </div>
      <span class="subtitle">{{ "MEMBERSHIPS_SUBTITLE" | transloco }}</span>
    </div>
    <img class="logo" src="assets/img/aau-logo.png" />
  </div>

  <div class="m-card" *ngFor="let m of memberships">
    <div class="c-card-header" (click)="expand(m)">
      <div class="info">
        <div class="m-title-section">
          <div class="m-title">
            <span>{{ m.title }}</span>
            <span *ngIf="m.titleNote">
              <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                <circle cx="2" cy="2" r="2" fill="#1B1B1B" />
              </svg>
            </span>
            <span *ngIf="m.titleNote">{{ m.titleNote }}</span>
          </div>
          <div class="ath-selected" [class.selected-blue]="m.selectedAthletes > 0">
            {{ m.selectedAthletes }} {{ "ATHLETES_SELECTED" | transloco }}
          </div>
        </div>

        <span class="note">{{ m.note1 }}</span>
        <span class="note">{{ m.note2 }}</span>
      </div>
      <div class="price">
        <span>USD {{ m.totalPrice | number : "1.2-2" }}</span>
      </div>
      <div class="expand">
        <svg [class.rotate]="m.expanded" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
          fill="none">
          <path
            d="M7.54639 11.8144L2.18557 6.4811C1.93814 6.20619 1.93814 5.79381 2.18557 5.54639L2.81787 4.91409C3.06529 4.66667 3.47766 4.66667 3.75258 4.91409L8.01375 9.14777L12.2474 4.91409C12.5223 4.66667 12.9347 4.66667 13.1821 4.91409L13.8144 5.54639C14.0619 5.79381 14.0619 6.20619 13.8144 6.4811L8.45361 11.8144C8.20619 12.0619 7.79381 12.0619 7.54639 11.8144Z"
            fill="#A3A3A3" />
        </svg>
      </div>
    </div>

    <div *ngIf="m.expanded" class="athletes">
      <div *ngFor="let ath of membersData.items">
        <div class="ath-header" [class.ath-selected-row]="
                                  ath.selectedMembershipId && ath.selectedMembershipId === m.id
                                ">
          <div class="athlete-card">
            <div class="f-row">
              <span class="ath-name">{{ getName(ath) }}</span>
              <span class="ath-data">{{ ath.gender | transloco }}</span>
              <span *ngIf="ath.birthDate" class="ath-data">{{
                getAge(ath)
                }}</span>
              <span class="ath-price">USD 22.00</span>
              <div *ngIf="!isAdult(ath.birthDate)">
                <button class="small-primary-btn" (click)="selectAth(ath, m)" *ngIf="
                                                    !ath.selectedMembershipId || ath.selectedMembershipId !== m.id
                                                  ">
                  Select athlete
                </button>
              </div>
              <div class="unselect-container" *ngIf="!isAdult(ath.birthDate) &&
                                      ath.selectedMembershipId && ath.selectedMembershipId === m.id 
                                    ">
                <span class="added">
                  Added
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <path
                      d="M5.45026 13.7644L0.235602 8.54974C-0.078534 8.2356 -0.078534 7.70157 0.235602 7.38743L1.36649 6.25654C1.68063 5.94241 2.18325 5.94241 2.49738 6.25654L6.04712 9.77487L13.5864 2.2356C13.9005 1.92147 14.4031 1.92147 14.7173 2.2356L15.8482 3.36649C16.1623 3.68063 16.1623 4.21466 15.8482 4.5288L6.61257 13.7644C6.29843 14.0785 5.7644 14.0785 5.45026 13.7644Z"
                      fill="#7A7A7A" />
                  </svg>
                </span>
                <span class="delete-ath-selection" (click)="removeAthlete(ath)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M13.8125 1.875C14.0312 1.875 14.25 2.09375 14.25 2.3125V3.1875C14.25 3.43359 14.0312 3.625 13.8125 3.625H2.4375C2.19141 3.625 2 3.43359 2 3.1875V2.3125C2 2.09375 2.19141 1.875 2.4375 1.875H5.71875L5.96484 1.38281C6.07422 1.16406 6.29297 1 6.53906 1H9.68359C9.92969 1 10.1484 1.16406 10.2578 1.38281L10.5312 1.875H13.8125ZM3.44922 13.7695L2.875 4.5H13.375L12.7734 13.7695C12.7461 14.4805 12.1719 15 11.4609 15H4.76172C4.05078 15 3.47656 14.4805 3.44922 13.7695Z"
                      fill="#E15F5F" />
                  </svg>
                </span>
              </div>
            </div>
            <div class="adult-card-section" *ngIf="isAdult(ath.birthDate)">
              <div class="adult-card-title">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.5768 13.5572C16.0693 14.4328 15.4399 15.5 14.4275 15.5H1.32027C0.307808 15.5 -0.321557 14.4055 0.17099 13.5572L6.73828 2.14651C7.23083 1.27087 8.51692 1.29823 9.00947 2.14651L15.5768 13.5572ZM7.88756 11.1765C7.1761 11.1765 6.62883 11.7512 6.62883 12.4353C6.62883 13.1467 7.1761 13.694 7.88756 13.694C8.57165 13.694 9.14629 13.1467 9.14629 12.4353C9.14629 11.7512 8.57165 11.1765 7.88756 11.1765ZM6.68355 6.66152L6.8751 10.383C6.90246 10.5745 7.03928 10.684 7.20346 10.684H8.54429C8.70847 10.684 8.84529 10.5745 8.87265 10.383L9.0642 6.66152C9.09156 6.46997 8.92738 6.30579 8.73583 6.30579H7.01192C6.82037 6.30579 6.65619 6.46997 6.68355 6.66152Z"
                    fill="#D10D14" />
                </svg>
                <span class="">Felony / Sex Crime Conviction:</span>
              </div>
              <span class="adult-card-subtitle">If you have been convicted of a sex crime or felony, we are unable to
                continue with your membership
                application
                online.
                You must apply through the AAU National Office.</span>
              <div>
                <div class="adult-questions">
                  <div class="question">
                    <span class="radio-label">Felony Crime Conviction</span>
                    <mat-radio-group [(ngModel)]="ath.questionFelonyCrime">
                      <mat-radio-button [value]="'yes'" class="radio-label-q">Yes</mat-radio-button>
                      <mat-radio-button [value]="'no'" class="radio-label-q">No</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="question">
                    <span class="radio-label">Sex Crime Conviction</span>
                    <mat-radio-group [(ngModel)]="ath.questionSexCrime">
                      <mat-radio-button [value]="'yes'" class="radio-label-q">Yes</mat-radio-button>
                      <mat-radio-button [value]="'no'" class="radio-label-q">No</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="adult-button">
                    <button class="small-primary-btn"
                      [disabled]="!ath.questionSexCrime || !ath.questionFelonyCrime  || ath.questionSexCrime === 'yes' || ath.questionFelonyCrime === 'yes'"
                      (click)="selectAth(ath, m)"
                      *ngIf="!ath.selectedMembershipId || ath.selectedMembershipId !== m.id">
                      Select athlete
                    </button>
                    <div class="unselect-container" *ngIf="
                                                            ath.selectedMembershipId && ath.selectedMembershipId === m.id
                                                          ">
                      <span class="added">
                        Added
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                          <path
                            d="M5.45026 13.7644L0.235602 8.54974C-0.078534 8.2356 -0.078534 7.70157 0.235602 7.38743L1.36649 6.25654C1.68063 5.94241 2.18325 5.94241 2.49738 6.25654L6.04712 9.77487L13.5864 2.2356C13.9005 1.92147 14.4031 1.92147 14.7173 2.2356L15.8482 3.36649C16.1623 3.68063 16.1623 4.21466 15.8482 4.5288L6.61257 13.7644C6.29843 14.0785 5.7644 14.0785 5.45026 13.7644Z"
                            fill="#7A7A7A" />
                        </svg>
                      </span>
                      <span class="delete-ath-selection" (click)="removeAthlete(ath)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path
                            d="M13.8125 1.875C14.0312 1.875 14.25 2.09375 14.25 2.3125V3.1875C14.25 3.43359 14.0312 3.625 13.8125 3.625H2.4375C2.19141 3.625 2 3.43359 2 3.1875V2.3125C2 2.09375 2.19141 1.875 2.4375 1.875H5.71875L5.96484 1.38281C6.07422 1.16406 6.29297 1 6.53906 1H9.68359C9.92969 1 10.1484 1.16406 10.2578 1.38281L10.5312 1.875H13.8125ZM3.44922 13.7695L2.875 4.5H13.375L12.7734 13.7695C12.7461 14.4805 12.1719 15 11.4609 15H4.76172C4.05078 15 3.47656 14.4805 3.44922 13.7695Z"
                            fill="#E15F5F" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="terms">
    <span class="terms-title">Terms and conditions</span>
    <span class="terms-descr">Review and sign</span>
    <span class="terms-note">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M15.5768 13.0572C16.0693 13.9328 15.4399 15 14.4275 15H1.32027C0.307808 15 -0.321557 13.9055 0.17099 13.0572L6.73828 1.64651C7.23083 0.77087 8.51692 0.798233 9.00947 1.64651L15.5768 13.0572ZM7.88756 10.6765C7.1761 10.6765 6.62883 11.2512 6.62883 11.9353C6.62883 12.6467 7.1761 13.194 7.88756 13.194C8.57165 13.194 9.14629 12.6467 9.14629 11.9353C9.14629 11.2512 8.57165 10.6765 7.88756 10.6765ZM6.68355 6.16152L6.8751 9.88299C6.90246 10.0745 7.03928 10.184 7.20346 10.184H8.54429C8.70847 10.184 8.84529 10.0745 8.87265 9.88299L9.0642 6.16152C9.09156 5.96997 8.92738 5.80579 8.73583 5.80579H7.01192C6.82037 5.80579 6.65619 5.96997 6.68355 6.16152Z"
          fill="#FFC123" />
      </svg>
      You must read through terms and conditions before proceeding
    </span>

    <div class="border-terms">

      <div class="inner-border-terms marginb" [class.terms-success]="agreed">
        Membership in the AAU is a privilege granted by the AAU. It is not a right. The AAU at its sole discretion
        reserves the right to accept or reject any applicant(s) for membership.<br><br>Membership in any category may be
        granted only after an application is submitted and approved. By submitting an application, the applicant agrees
        to comply with all the provisions of the <u>AAU Code, including its constitution, bylaws, policies, procedures,
          regulations, and rules*</u>.<br><br> I certify that I have the athlete's parent's or guardian's consent for
        the athlete to become an AAU Member.<br><br>* I accept all terms and conditions for this AAU membership
        application as laid out by the AAU code book (available here) and this application.<br><br>* I hereby certify
        that all information I have provided is accurate, my name (below) is correct, and I am authorized to apply for
        membership for the youths in this application.<br><br>* I understand that there are no refunds issued for
        cancellations.<br><br>Membership must always be paid and member number issued prior to participation in any
        activity (including practices and try-outs)
      </div>
      <div class="inner-border-terms marginb" [class.terms-success]="agreed">
        <img src='assets/icons/red-warn.svg' class='term-icon'> <span>Adult Athletes are NOT permitted to engage with
          youth athletes as coaches, officials, club organizers, event hosts or the like without a separate Non-Athlete
          membership.</span>
      </div>
      <div class="inner-border-terms marginb" [class.terms-success]="agreed">
        By entering my name below I hereby authorize AAU to create the requested youth memberships, accept and
        acknowledge all terms and conditions presented to me during the application process.
      </div>
      <div class="inner-border-terms" [class.terms-success]="agreed">
        By entering my name below I hereby authorize AAU to perform a background screening for my adult membership,
        accept and acknowledge all terms and conditions presented to me during the application process.<br><br>NOTE:
        THIS MUST BE SIGNED BY THE PERSON APPLYING FOR MEMBERSHIP OR A PARENTALLY APPROVED REPRESENTATIVE FOR YOUTH
        APPLICANTS.<br><br>* I understand that Adult Athletes are NOT permitted to engage with youth athletes as
        coaches, officials, club organizers, event hosts or the like without a separate Non-Athlete membership.
      </div>
    </div>

    <mat-checkbox [(ngModel)]="agreed" class="agree">
      I have read and agree to Terms and conditions
    </mat-checkbox>
  </div>

  <div class="digital-signature" [class.disabled]="!agreed">
    <span class="digital-title" [class.disabled]="!agreed">
      Digital Signature
    </span>
    <span class="terms-note" [class.disabled]="!agreed">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M15.5768 13.0572C16.0693 13.9328 15.4399 15 14.4275 15H1.32027C0.307808 15 -0.321557 13.9055 0.17099 13.0572L6.73828 1.64651C7.23083 0.77087 8.51692 0.798233 9.00947 1.64651L15.5768 13.0572ZM7.88756 10.6765C7.1761 10.6765 6.62883 11.2512 6.62883 11.9353C6.62883 12.6467 7.1761 13.194 7.88756 13.194C8.57165 13.194 9.14629 12.6467 9.14629 11.9353C9.14629 11.2512 8.57165 10.6765 7.88756 10.6765ZM6.68355 6.16152L6.8751 9.88299C6.90246 10.0745 7.03928 10.184 7.20346 10.184H8.54429C8.70847 10.184 8.84529 10.0745 8.87265 9.88299L9.0642 6.16152C9.09156 5.96997 8.92738 5.80579 8.73583 5.80579H7.01192C6.82037 5.80579 6.65619 5.96997 6.68355 6.16152Z"
          fill="#FFC123" />
      </svg>
      Non-Youth: This must be signed by the person applying for membership
    </span>
    <span class="terms-note" [class.disabled]="!agreed">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M15.5768 13.0572C16.0693 13.9328 15.4399 15 14.4275 15H1.32027C0.307808 15 -0.321557 13.9055 0.17099 13.0572L6.73828 1.64651C7.23083 0.77087 8.51692 0.798233 9.00947 1.64651L15.5768 13.0572ZM7.88756 10.6765C7.1761 10.6765 6.62883 11.2512 6.62883 11.9353C6.62883 12.6467 7.1761 13.194 7.88756 13.194C8.57165 13.194 9.14629 12.6467 9.14629 11.9353C9.14629 11.2512 8.57165 10.6765 7.88756 10.6765ZM6.68355 6.16152L6.8751 9.88299C6.90246 10.0745 7.03928 10.184 7.20346 10.184H8.54429C8.70847 10.184 8.84529 10.0745 8.87265 9.88299L9.0642 6.16152C9.09156 5.96997 8.92738 5.80579 8.73583 5.80579H7.01192C6.82037 5.80579 6.65619 5.96997 6.68355 6.16152Z"
          fill="#FFC123" />
      </svg>
      Youth: This must be signed by the parentally approved representative
    </span>

    <form [formGroup]="signatureForm" class="form-name" [class.disabled]="!agreed">
      <mat-form-field appearance="fill" class="m-w-225">
        <mat-label>{{ "FIRST_NAME" | transloco }}</mat-label>
        <input matInput formControlName="firstName" (input)="updateFormWithEvent('firstName', $event)" />
        <mat-error *ngIf="
            signatureForm.get('firstName')?.hasError('required') &&
            (signatureForm.get('firstName')?.touched || submitted)
          ">
          {{ "REQUIRED" | transloco }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="m-w-225">
        <mat-label>{{ "LAST_NAME" | transloco }}</mat-label>
        <input matInput formControlName="lastName" (input)="updateFormWithEvent('lastName', $event)" />
        <mat-error *ngIf="
            signatureForm.get('lastName')?.hasError('required') &&
            (signatureForm.get('lastName')?.touched || submitted)
          ">
          {{ "REQUIRED" | transloco }}
        </mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="end-buttons">
    <div></div>
    <button class="success-button" (click)="goToMembershipsStep()" [disabled]="!signatureForm.valid || !agreed">
      {{ "NEXT_STEP" | transloco }}
    </button>
  </div>
</div>