<div class="c-pad custom-component-theme">
  <div class="header">
    <div class="left-header">
      <div class="title-section">
        <svg
          width="23"
          height="21"
          viewBox="0 0 23 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.625 12.2969C20.5078 12.7266 20.1172 13 19.6875 13H8.24219L8.51562 14.25H18.9844C19.5703 14.25 20.0391 14.8359 19.8828 15.4219L19.6875 16.3594C20.4297 16.7109 20.9375 17.4531 20.9375 18.3125C20.9375 19.5234 19.9219 20.5 18.75 20.5C17.5391 20.5 16.5625 19.5234 16.5625 18.3125C16.5625 17.7266 16.7969 17.1797 17.1875 16.75H9.02344C9.41406 17.1797 9.6875 17.7266 9.6875 18.3125C9.6875 19.5234 8.67188 20.5 7.5 20.5C6.28906 20.5 5.3125 19.5234 5.3125 18.3125C5.3125 17.5312 5.74219 16.8281 6.40625 16.4375L3.63281 3H0.9375C0.390625 3 0 2.60938 0 2.0625V1.4375C0 0.929688 0.390625 0.5 0.9375 0.5H4.92188C5.35156 0.5 5.74219 0.851562 5.85938 1.28125L6.21094 3H21.5234C22.1484 3 22.5781 3.58594 22.4609 4.17188L20.625 12.2969Z"
            fill="var(--customizable-primary-color)"
          />
        </svg>
        <span class="title">{{ "NAV_ROSTER_STEP_THREE" | transloco }}</span>
      </div>
      <span class="subtitle">{{
        "ROSTER_SHOPPING_CART_SUBTITLE" | transloco
      }}</span>
    </div>
    <img class="logo" src="assets/img/aau-logo.png" />
  </div>

  <div class="m-card">
    <div *ngFor="let ath of athletes">
      <div class="ath-header">
        <div class="f-row">
          <span class="ath-name">{{ ath.name }}</span>
          <span class="ath-data">{{ ath.gender | transloco }}</span>
          <span class="ath-data">{{ "Age " + ath.age }}</span>
        </div>
        <div class="coverage-type">
          <span *ngIf="ath.extendedBenefit">{{
            "EXTENDED_BENEFIT" | transloco
          }}</span>
          <svg
            *ngIf="ath.extendedBenefit"
            width="4"
            height="4"
            viewBox="0 0 4 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="#0E2A8D" />
          </svg>
          <span>{{ getCoverageType(ath.extendedBenefit) | transloco }}</span>
        </div>
        <div class="f-row">
          <span class="ath-price">{{ ath.price | currency }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="total">
    <div class="horizontal-line"></div>
    <div class="total-item">
      <span class="total-label">{{ "TOTAL" | transloco }}</span>
      <span class="total-amount">{{totalAmount | currency}} </span>
    </div>
    <div class="horizontal-line"></div>
  </div>

  <div>
    <span class="auto-enroll-announcement">{{
      "ROSTER_AUTO_RENEWAL_OFFER" | transloco
    }}</span>
    <div class="auto-enroll-enabled">
      <mat-radio-button>{{ "ACCEPT_ENROLL_ALL" | transloco }}</mat-radio-button>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.16793 7.28902V10.3557C9.16793 10.6474 9.32627 10.9224 9.57627 11.0724L12.1763 12.614C12.4763 12.789 12.8596 12.689 13.0346 12.3974C13.2096 12.0974 13.1179 11.714 12.8179 11.539L10.4263 10.114V7.28069C10.4179 6.94735 10.1346 6.66402 9.79293 6.66402C9.45127 6.66402 9.16793 6.94735 9.16793 7.28902ZM17.5013 7.91402V3.50569C17.5013 3.13069 17.0513 2.94735 16.7929 3.21402L15.3096 4.69735C13.8013 3.18902 11.6513 2.32235 9.30127 2.53069C5.8096 2.84735 2.9346 5.65569 2.55127 9.14735C2.05127 13.664 5.57627 17.4974 10.0013 17.4974C13.8263 17.4974 16.9846 14.6307 17.4429 10.9307C17.5013 10.4307 17.1096 9.99735 16.6096 9.99735C16.1929 9.99735 15.8429 10.3057 15.7929 10.714C15.4346 13.6224 12.9263 15.8724 9.91793 15.8307C6.82627 15.789 4.21793 13.1807 4.16793 10.0807C4.11793 6.83069 6.7596 4.16402 10.0013 4.16402C11.6096 4.16402 13.0679 4.82235 14.1263 5.87235L12.3846 7.61402C12.1179 7.88069 12.3013 8.33069 12.6763 8.33069H17.0846C17.3179 8.33069 17.5013 8.14735 17.5013 7.91402Z"
          fill="#1B1B1B"
        />
      </svg>
      <span>{{ "MEMBERSHIP_AUTOMATIC_RENEWAL" | transloco }}</span>
    </div>
  </div>

  <div class="terms">
    <mat-checkbox [(ngModel)]="agreed" class="agree">
      {{ "LEGAL_PARTICIPANT" | transloco }}
    </mat-checkbox>
  </div>

  <div class="end-buttons">
    <div></div>
    <button
      class="success-button"
      (click)="goToMembershipsStep()"
    >
      {{ "PROCEED_TO_PAYMENT" | transloco }}
    </button>
  </div>
</div>
