import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { LocalizationModule } from "src/app/components/localization";
import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { BottomBannerComponent } from "src/app/components/bottom-banner/bottom-banner.component";
import { Select, Store } from "@ngxs/store";
import { EventsService } from "src/app/shared/constants/events.service";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { UserState } from "src/app/store/user/user.store";
import { firstValueFrom, map, Observable, skip, Subscription } from "rxjs";
import { User } from "src/app/shared/models/user.model";
import { Location } from "@angular/common";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

import { DictionariesContract } from "src/app/shared/contracts/dictionaries.contract";
import { PhoneMaskDirective } from "src/app/shared/directives/phone-mask.directive";
import { CustomDatePickerComponent } from "src/app/components/custom-date-picker/custom-date-picker.component";
import { CustomSearchableSelectComponent } from "src/app/components/custom-searchable-select/custom-searchable-select.component";
import { ApiService } from "src/app/shared/constants/api.service";
import { ImgCropInputFieldComponent } from "src/app/components/img-crop-input-field/img-crop-input-field.component";
import { NCSABannerComponent } from "src/app/components/ncsa-banner/ncsa-banner.component";
import { CustomMaterialInputComponent } from "src/app/components/custom-material-input/custom-material-input.component";
import { ToastrService } from "ngx-toastr";
import zipcodes from 'zipcodes';
import { LoadImageService } from "ngx-image-cropper";

@Component({
  selector: "app-registration-athlete",
  templateUrl: "./registration-athlete.component.html",
  standalone: true,
  imports: [
    LocalizationModule,
    NgFor,
    NgIf,
    BottomBannerComponent,
    PhoneMaskDirective,
    NgClass,
    RouterModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    CommonModule,
    CustomDatePickerComponent,
    CustomSearchableSelectComponent,
    ImgCropInputFieldComponent,
    NCSABannerComponent,
  ],
  styleUrls: ["./registration-athlete.component.scss"],
})
export class RegistrationAthleteComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Select(UserState.authUser)
  public authUser$!: Observable<User>;

  @Select(UserState.isAdmin)
  isAdmin$!: Observable<boolean>;

  user: User | undefined | null;

  subscriptions: Subscription[] = [];

  athleteForm!: FormGroup;

  countries: any[] = [];
  countryNames: string[] = [];
  filteredCountries: any[] = [];
  searchTextCountry: string = "";

  isConfirmEmailEqual = false;
  isLargeScreen: Observable<boolean>;
  graduationYears: string[] = [];

  submitted = false;

  selectedCountry: any;
  selectedCountryName: string = "United States";

  showShareInformation = false;
  middleNameRequired: boolean = false;
  iHaveMiddleName: boolean = true;

  sports: string[] | undefined;
  clubs: any[] | undefined;
  myself: boolean = false;
  adult: boolean = true;
  completeSports: any;

  dateOfBirthError: string | undefined;
  email: string | undefined;
  phone: string = "";
  middleName: string | undefined;
  eventId: number = 0;
  updateId: number = 0;
  selfPerson: any;

  dateOfBirth: Date | undefined;

  selectedClub: any | undefined;

  infoLabel: string = "";
  editingPerson: any;

  isWantsNCSARecruiting = false;
  image: any;

  constructor(
    private fb: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    private dictionariesContract: DictionariesContract,
    private api: ApiService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {
    this.athleteForm = this.fb.group({
      signingUpAs: ["", Validators.required],
      primarySport: ["", Validators.required],
      firstName: ["", Validators.required],
      middleName: [""],
      lastName: ["", Validators.required],
      dateOfBirth: ["", Validators.required],
      birthGender: ["", Validators.required],
      country: [228, Validators.required], //UNITED STATES
      postalCode: ["", Validators.required],
      homeAddress: ["", Validators.required],
      graduationYear: [""],
      clubDetails: ["", Validators.required],
      guardianEmail: ["", [Validators.required, Validators.email]],
      guardianfirstName: [""],
      guardianLastName: [""],
      comfirmGuardianEmail: ["", [Validators.required, Validators.email]],
      countryCode: [228],
      phoneNumber: [""],
      shareInformation: [false],
    });
    const customBreakpoint = "(min-width: 1430px)"; // Adjust to your maxScreen
    this.isLargeScreen = this.breakpointObserver
      .observe(customBreakpoint)
      .pipe(map((result) => result.matches));
  }

  reInitFormAndValues(value?: string) {
    if (!value) {
      this.athleteForm.patchValue({ signingUpAs: "" });
    }
    this.athleteForm.patchValue({
      primarySport: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: "",
      birthGender: "",
      country: 228,
      postalCode: "",
      homeAddress: "",
      graduationYear: "",
      clubDetails: "",
      guardianEmail: "",
      guardianfirstName: "",
      guardianLastName: "",
      comfirmGuardianEmail: "",
      countryCode: 228,
      phoneNumber: "",
      shareInformation: false,
    });

    this.searchTextCountry = "";

    this.isConfirmEmailEqual = false;

    this.submitted = false;
    this.selectedCountryName = "United States";

    this.showShareInformation = false;
    this.middleNameRequired = false;
    this.iHaveMiddleName = true;

    this.myself = false;
    this.adult = true;

    this.dateOfBirth = undefined;

    this.selectedClub = undefined;

    this.dateOfBirthError = undefined;
    this.email = undefined;
    this.phone = "";
    this.middleName = undefined;

    this.athleteForm.markAsUntouched();
  }

  listenToValues() {
    const s1 = this.athleteForm
      .get("country")
      ?.valueChanges.subscribe((value) => {
        this.changePrefixCountry(value);
        this.athleteForm.patchValue({ countryCode: value });
        this.checkNcsaConfig();
        this.checkPostalCodeValidity(false);
      });
    if (s1) {
      this.subscriptions.push(s1);
    }

    const s2 = this.athleteForm
      .get("postalCode")
      ?.valueChanges.subscribe((value) => {
        this.checkPostalCodeValidity(true);
      });

    if (s2) {
      this.subscriptions.push(s2);
    }

    const s3 = this.athleteForm
      .get("middleName")
      ?.valueChanges.subscribe((value) => {
        if (value && value.trim() && value.trim().length > 0) {
          this.middleName = value.trim();
          this.iHaveMiddleName = true;
        }
      });
    if (s3) {
      this.subscriptions.push(s3);
    }

    const s4 = this.athleteForm
      .get("signingUpAs")
      ?.valueChanges.subscribe(async (value) => {
        if (value) {
          this.reInitFormAndValues(value);
        }
        this.myself = false;
        this.checkAge();
        const middleNameControl = this.athleteForm.get("middleName");
        if (!middleNameControl) {
          return;
        }
        const homeAddressControl = this.athleteForm.get("homeAddress");
        if (value && value === "myself") {
          this.myself = true;
          this.adult = true;
          this.middleNameRequired = true;
          homeAddressControl?.setValidators([Validators.required]);
          this.updateFormWithSelfData();
        } else {
          this.middleNameRequired = false;
          homeAddressControl?.clearValidators();
        }
        homeAddressControl?.updateValueAndValidity();
        await this.checkNcsaConfig();
        this.trySetEmail();
        this.infoLabel = this.getInfoLabel(value);
        this.cdr.detectChanges();
        setTimeout(() => {
          document.body.click();
        }, 50);
        setTimeout(() => {
          document.body.click();
        }, 350);
        setTimeout(() => {
          document.body.click();
        }, 700);
        setTimeout(() => {
          document.body.click();
        }, 1000);
      });

    if (s4) {
      this.subscriptions.push(s4);
    }

    const s5 = this.athleteForm
      .get("guardianEmail")
      ?.valueChanges.subscribe((value) => {
        this.checkValidConfirmEmail(
          value,
          this.athleteForm.get("comfirmGuardianEmail")?.value
        );
      });

    if (s5) {
      this.subscriptions.push(s5);
    }

    const s6 = this.athleteForm
      .get("comfirmGuardianEmail")
      ?.valueChanges.subscribe((value) => {
        this.checkValidConfirmEmail(
          this.athleteForm.get("guardianEmail")?.value,
          value
        );
      });
    if (s6) {
      this.subscriptions.push(s6);
    }

    const s7 = this.athleteForm
      .get("dateOfBirth")
      ?.valueChanges.subscribe((dob) => {
        this.checkAge();
        this.checkNcsaConfig();
      });
    if (s7) {
      this.subscriptions.push(s7);
    }
  }

  async updateFormWithSelfData() {
    if (!this.selfPerson) {
      this.selfPerson = await this.api.getSelfPerson();
    }
    if (!this.selfPerson) {
      return;
    }
    this.updateId = this.selfPerson.id;
    this.updateFormWithPersonData(this.selfPerson);
  }

  updateFormWithPersonData(person: any) {
    if (person.address) {
      this.athleteForm.patchValue({ homeAddress: person.address });
    }
    if (person.birthDate) {
      this.dateOfBirth = new Date(person.birthDate + "T00:00:00");
      this.athleteForm.patchValue({ dateOfBirth: person.birthDate });
    }

    if (person.country && person.country.id) {
      this.selectedCountryName = person.country.name;
      this.athleteForm.patchValue({ country: person.country.id });
    }

    if (person.club && person.club.id) {
      this.selectedClub = this.clubs?.find((x) => x.id === person.club.id);
      this.athleteForm.patchValue({ clubDetails: person.club.id });
    }

    if (person.primarySport && person.primarySport.id) {
      this.completeSports?.find((x: any) => x.id === person.primarySport.id);
      this.athleteForm.patchValue({ primarySport: person.primarySport.name });
    }

    if (person.lastName) {
      this.athleteForm.patchValue({ lastName: person.lastName });
    }
    if (person.firstName) {
      this.athleteForm.patchValue({ firstName: person.firstName });
    }
    if (person.middleName) {
      this.middleName = person.middleName;
      this.athleteForm.patchValue({ middleName: person.middleName });
    }

    if (person.doNotUseMiddleName) {
      this.iHaveMiddleName = false;
    } else {
      this.iHaveMiddleName = true;
    }

    if (person.gender) {
      this.athleteForm.patchValue({ birthGender: person.gender });
    }

    if (person.phoneNumber) {
      this.phone = person.phoneNumber.slice(-10);
      this.athleteForm.patchValue({
        phoneNumber: person.phoneNumber.slice(-10),
      });
    }
    if (person.zipCode) {
      this.athleteForm.patchValue({ postalCode: person.zipCode });
    }

    if (person.guardian) {
      if (person.guardian.email) {
        this.athleteForm.patchValue({ guardianEmail: person.guardian.email });
        this.athleteForm.patchValue({
          comfirmGuardianEmail: person.guardian.email,
        });
      }
      if (person.guardian.firstName) {
        this.athleteForm.patchValue({
          guardianfirstName: person.guardian.firstName,
        });
      }
      if (person.guardian.lastName) {
        this.athleteForm.patchValue({
          guardianLastName: person.guardian.lastName,
        });
      }
    }

    this.checkNcsaConfig();
  }

  checkPostalCodeValidity(fromPostalCode: boolean) {
    const countryId = this.athleteForm.get("country")?.value;
    if (!countryId) {
      return;
    }
    const country = this.countries.find((x) => x.id === countryId);
    if (!country) {
      return;
    }
    const postalCodeControl = this.athleteForm.get("postalCode");
    if (!postalCodeControl) return;

    const postalCode = postalCodeControl?.value;

    if (country.code === "US" || country.code === "CA") {
      if (this.validatePostalCode(postalCode, country.code)) {
        postalCodeControl.setErrors(null);
      } else {
        postalCodeControl.setErrors({ invalidPostalCode: true });
      }
    } else {
      postalCodeControl.setErrors(null);
    }
  }

  updateForm(formInput: string, value: string) {
    const control = this.athleteForm.get(formInput);
    if (control) {
      control.setValue(value);
      control.markAsTouched();
    }
  }

  updateFormWithEvent(inputValue: string, event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.updateForm(inputValue, inputElement.value);
  }

  checkAge() {
    const dateOfBirth = this.athleteForm.get("dateOfBirth")?.value;
    const signingUpAs = this.athleteForm.get("signingUpAs")?.value;

    const age = this.getAge(dateOfBirth);
    this.dateOfBirthError = undefined;
    if (signingUpAs === "myself") {
      if (age < 18) {
        this.dateOfBirthError = "Too young for 'Myself' option";
      }
      return;
    }

    if (age < 3) {
      this.dateOfBirthError = "Too young";
    } else if (age >= 18 && signingUpAs === "myChild") {
      this.dateOfBirthError = "Adults should register with 'Myself' option";
      return;
    }

    if (age >= 18) {
      this.adult = true;
    } else {
      this.adult = false;
    }
  }

  async checkNcsaConfig() {
    const dateOfBirth = this.athleteForm.get("dateOfBirth")?.value;
    const countryId = this.athleteForm.get("country")?.value;
    const signingUpAs = this.athleteForm.get("signingUpAs")?.value;
    const primarySport = this.athleteForm.get("primarySport")?.value;

    this.showShareInformation = false;
    this.graduationYears = [];
    this.athleteForm.patchValue({ shareInformation: false });

    if (!signingUpAs || signingUpAs === "myself") {
      return;
    }

    if (!countryId || !dateOfBirth || !primarySport) {
      return;
    }

    const sport = this.completeSports?.find(
      (x: any) => x.name === primarySport
    );
    if (!sport || !sport.id) {
      return;
    }

    try {
      let birthDate: string = "";
      if (typeof this.athleteForm.value.dateOfBirth === "string") {
        birthDate = this.athleteForm.value.dateOfBirth;
      } else if (this.athleteForm.value.dateOfBirth instanceof Date) {
        birthDate = this.athleteForm.value.dateOfBirth
          .toISOString()
          .split("T")[0];
      }
      const data: any = await firstValueFrom(
        this.api.getRegistrationConfig(countryId, sport.id, birthDate)
      );

      if (data && data.isGraduationYearRequired) {
        this.initGraduationYears();
        const graduationYear =
          this.editingPerson &&
          this.editingPerson.graduationYear &&
          this.updateId
            ? this.editingPerson.graduationYear
            : this.selfPerson && this.selfPerson.graduationYear && this.updateId
            ? this.selfPerson.graduationYear
            : dateOfBirth
            ? this.getGraduationYear(new Date(birthDate + "T00:00:00"))
            : null;
        if (graduationYear) {
          this.athleteForm.patchValue({ graduationYear: "" + graduationYear });
        } else {
          this.athleteForm.patchValue({ graduationYear: "" });
        }
      } else {
        this.graduationYears = [];
        this.athleteForm.patchValue({ graduationYear: "" });
      }

      if (data && data.isNCSASupported) {
        const acceptSharing =
          this.editingPerson &&
          this.editingPerson.isWantsNCSARecruiting &&
          this.updateId
            ? this.editingPerson.isWantsNCSARecruiting
            : this.selfPerson &&
              this.selfPerson.isWantsNCSARecruiting &&
              this.updateId
            ? this.selfPerson.isWantsNCSARecruiting
            : false;
        this.isWantsNCSARecruiting = acceptSharing;
        this.athleteForm.patchValue({ shareInformation: acceptSharing });
        this.showShareInformation = true;
      } else {
        this.showShareInformation = false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  updateImgForm(event: any) {
    this.image = event;
  }

  filterCountries() {
    this.filteredCountries = this.countries.filter(
      (country) =>
        country.phonePrefix.includes(this.searchTextCountry) ||
        country.code
          .toLowerCase()
          .includes(this.searchTextCountry.toLowerCase())
    );
  }

  onCheckboxChange() {
    this.iHaveMiddleName = !this.iHaveMiddleName;
    if (!this.iHaveMiddleName) {
      this.athleteForm.get("middleName")?.patchValue("");
      this.middleName = "";
    }
  }

  handleDateSelected(date: Date) {
    this.dateOfBirth = date;
    this.athleteForm.get("dateOfBirth")?.patchValue(date);
  }

  changePrefixCountry(id: number) {
    this.selectedCountry = this.countries.find((c) => c.id === id);
  }

  checkValidConfirmEmail(email: string, confirmEmail: string) {
    if (!email || !confirmEmail) {
      this.isConfirmEmailEqual = false;
      return;
    }

    if (
      !this.athleteForm.get("comfirmGuardianEmail")?.valid ||
      !this.athleteForm.get("guardianEmail")?.valid
    ) {
      this.isConfirmEmailEqual = false;
      return;
    }
    const first = email.trim();
    const second = confirmEmail.trim();

    if (first === second) {
      this.isConfirmEmailEqual = true;
      return;
    }
    this.isConfirmEmailEqual = false;
  }

  initGraduationYears() {
    const year = new Date().getFullYear();
    this.graduationYears = [];
    for (let i = year; i < year + 13; i++) {
      this.graduationYears.push("" + i);
    }
  }

  getAge(dateOfBirth: string | Date): number {
    const dob =
      typeof dateOfBirth === "string"
        ? new Date(dateOfBirth + "T00:00:00")
        : dateOfBirth;
    const today = new Date();

    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();
    const dayDiff = today.getDate() - dob.getDate();

    // Adjust age if the birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age;
  }

  shareWithNcsa(share: boolean) {
    this.athleteForm.patchValue({ shareInformation: share });
  }

  selectCountry(event: any) {
    const country = this.countries.find((x) => x.name === event);
    this.athleteForm.patchValue({ country: country.id });
    this.selectedCountryName = country.name;
  }

  selectedSport(event: any) {
    this.athleteForm.patchValue({ primarySport: event });
    this.checkNcsaConfig();
  }

  selectClub(event: any) {
    if (!isNaN(event)) {
      this.selectedClub = this.clubs?.find((x) => x.id === event);
    } else if (event) {
      this.selectedClub = event;
    } else {
      this.selectedClub = undefined;
    }
    this.athleteForm.patchValue({ clubDetails: event });
  }

  async ngAfterViewInit() {
    this.subscriptions.push(
      this.authUser$.subscribe(async (user: any) => {
        if (user) {
          this.user = user;
          this.trySetEmail();

          //   const isAdmin = this.store.selectSnapshot(UserState.isAdmin);
          //   if (!isAdmin) {
          //     this.router.navigateByUrl("/");
          //   }
          // } else {
          //   this.router.navigateByUrl("/");
        }
      })
    );
  }
  trySetEmail() {
    const signingUpAs = this.athleteForm.get("signingUpAs")?.value;
    if (signingUpAs === "myself" && this.user && this.user.email) {
      this.athleteForm.patchValue({ guardianEmail: this.user.email });
      this.athleteForm.patchValue({ comfirmGuardianEmail: this.user.email });
      this.email = this.user.email;
      if (this.user.phoneNumber) {
        this.phone = "" + this.user.phoneNumber;
        this.athleteForm.patchValue({
          phoneNumber: this.phone,
        });
      }
    } else if (signingUpAs === "myChild" && this.user && this.user.email) {
      this.athleteForm.patchValue({ guardianLastName: this.user.lastName });
      this.athleteForm.patchValue({ guardianfirstName: this.user.firstName });
      this.athleteForm.patchValue({ guardianEmail: this.user.email });
      this.athleteForm.patchValue({ comfirmGuardianEmail: this.user.email });
      this.email = this.user.email;
      if (this.user.phoneNumber) {
        this.phone = "" + this.user.phoneNumber;
        this.athleteForm.patchValue({
          phoneNumber: this.phone,
        });
      }
    } else {
      this.athleteForm.patchValue({ guardianEmail: "" });
      this.athleteForm.patchValue({ comfirmGuardianEmail: "" });
      this.athleteForm.patchValue({ phoneNumber: "" });
      this.email = "";
      this.phone = "";
    }
  }

  getGraduationYear(dob: any) {
    if (!(dob instanceof Date)) {
      throw new Error("Invalid input: Expected a Date object");
    }

    return dob.getFullYear() + 18;
  }

  async ngOnInit(): Promise<void> {
    this.dictionariesContract.getCountries().subscribe((countries) => {
      countries.sort((a, b) => {
        if (a.name === "United States") return -1;
        if (b.name === "United States") return 1;
        if (a.name === "Canada") return -1;
        if (b.name === "Canada") return 1;
        return a.name.localeCompare(b.name);
      });
      this.countries = countries;
      this.filteredCountries = [...this.countries];
      this.countryNames = this.countries.map((country) => country.name);
      this.changePrefixCountry(228);
    });

    try {
      const data: any = await firstValueFrom(this.api.getSports());
      this.completeSports = data.items;
      this.sports = data.items.map((s: any) => s.name);
    } catch (e) {
      console.error(e);
    }

    try {
      const data: any = await firstValueFrom(this.api.getClubs());
      this.clubs = ["No club", ...data.items];
    } catch (e) {
      console.error(e);
    }

    this.listenToValues();

    this.eventId = +this.route.snapshot.params["eventId"];
    this.updateId = +this.route.snapshot.params["athleteId"];

    if (this.eventId && this.updateId && !isNaN(this.updateId)) {
      this.editingPerson = this.api.editingPerson;
      // if (!this.editingPerson) {
      // try {
      //   this.editingPerson = await firstValueFrom(
      //     this.api.getPerson(this.updateId)
      //   );
      //   console.log(this.editingPerson);
      // } catch (e) {
      //   console.error(e);
      // }
      // }

      // TODO:
      if (!this.editingPerson) {
        this.goBack();
        return;
      }
      this.athleteForm.get("signingUpAs")?.disable();

      this.athleteForm.patchValue({
        signingUpAs:
          this.editingPerson.relation === "SELF_PERSON"
            ? "myself"
            : this.editingPerson.isMyChild
            ? "myChild"
            : "someoneElse",
      });
      this.updateFormWithPersonData(this.editingPerson);
    } else {
      this.editingPerson = undefined;
      this.updateId = 0;
    }
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      try {
        s.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    }
  }

  async onSubmit(saveAnother?: boolean) {
    this.submitted = true;
    const email: string = this.athleteForm.value.guardianEmail;
    const firstName: string = this.athleteForm.value.firstName;
    const lastName: string = this.athleteForm.value.lastName;
    const middleName: string = this.athleteForm.value.middleName;
    const gender: "MALE" | "FEMALE" = this.athleteForm.value.birthGender;
    const relation: "SELF_PERSON" | "MINOR" =
      this.athleteForm.value.signingUpAs === "myself" ? "SELF_PERSON" : "MINOR";

    const isMyChild =
      this.athleteForm.value.signingUpAs === "myChild" ? true : false;

    const address: string = this.athleteForm.value.homeAddress;
    let birthDate: string = "";
    if (typeof this.athleteForm.value.dateOfBirth === "string") {
      birthDate = this.athleteForm.value.dateOfBirth;
    } else if (this.athleteForm.value.dateOfBirth instanceof Date) {
      birthDate = this.athleteForm.value.dateOfBirth
        .toISOString()
        .split("T")[0];
    }

    if (this.dateOfBirthError) {
      return;
    }

    const countryId: number = this.athleteForm.value.country;
    const zipCode: any = this.athleteForm.value.postalCode;

    if (this.athleteForm.valid) {
      const data: any = {
        email,
        firstName,
        lastName,
        middleName,
        gender,
        relation,
        address,
        zipCode,
        isMyChild,
        birthDate,
        country: { id: countryId },
      };

      if (!this.iHaveMiddleName) {
        data.doNotUseMiddleName = true;
      } else {
        data.doNotUseMiddleName = false;
      }

      const clubId: any = this.athleteForm.value.clubDetails;
      if (clubId && clubId !== "No club") {
        data.club = { id: clubId };
      } else {
        data.club = { id: null };
      }
      const primarySport = this.athleteForm.value.primarySport;
      const sport = this.completeSports?.find(
        (x: any) => x.name === primarySport
      );
      if (!sport || !sport.id) {
        return;
      }
      data.primarySport = { id: sport.id };

      const countryPhoneId: any = this.athleteForm.value.countryCode;

      const countryPhone = this.countries.find((x) => x.id === countryPhoneId);
      const phoneNumber: string = this.athleteForm.value.phoneNumber?.trim();

      if (countryPhone && phoneNumber) {
        const completePhone = (
          (countryPhone.phonePrefix ?? "") + phoneNumber
        ).replace(/[\s()]/g, "");
        data.phoneNumber = completePhone;
      }

      if (!this.myself) {
        const guardianEmail = this.athleteForm.value.guardianEmail;
        const guardianfirstName = this.athleteForm.value.guardianfirstName;
        const guardianLastName = this.athleteForm.value.guardianLastName;

        data.guardian = {
          email: guardianEmail,
          firstName: guardianfirstName,
          lastName: guardianLastName,
        };
      }

      data.graduationYear = +this.athleteForm.value.graduationYear;

      data.isWantsNCSARecruiting = this.showShareInformation
        ? this.athleteForm.value.shareInformation ?? false
        : null;

      try {
        if (this.updateId) {
          data.id = this.updateId;
          if (
            ((this.editingPerson?.photoUrl || this.selfPerson?.photoUrl) &&
              !this.image) ||
            this.image === ""
          ) {
            // TODO: console.log("delete photo")
            data.photoUrl = null;
          }
          await firstValueFrom(this.api.updatePerson(data));
          this.toastr.success("Athlete info updated");
          if (this.image && this.image !== "") {
            await firstValueFrom(this.api.uploadImage(this.image, data.id));
          }
          this.goBack();
        } else {
          const response: any = await firstValueFrom(this.api.addPerson(data));
          const id = response.id;
          if (this.image) {
            await firstValueFrom(this.api.uploadImage(this.image, id));
          }
          this.toastr.success("Athlete added to roaster");
        }

        this.reInitFormAndValues();

        if (!saveAnother) {
          this.goBack();
        } else {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      } catch (e) {
        console.error(e);
        this.toastr.error("Error updating info");
      }
    }
  }

  goBack() {
    // if (window.history.length > 1) {
    //   this.location.back();
    // } else {
    this.router.navigate(["/roster/" + this.eventId + "/athletes"]);
    // }
  }

  validatePostalCode(postalCode: string, country: "US" | "CA"): boolean {
    const usRegex = /^(?!0{5})\d{5}(-\d{4})?$/; // US ZIP Code: 12345 or 12345-6789, excluding 00000
    const caRegex = /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/; // Canadian Postal Code: A1A 1A1

    if (country === "US") {
      return usRegex.test(postalCode);
    } else if (country === "CA") {
      return caRegex.test(postalCode);
    }

    return false;
  }

  getInfoLabel(value: string): string {
    switch (value) {
      case "myself":
        return "NEW_ATHLETE_INFO_NOTE_MYSELF";
      case "myChild":
        return "NEW_ATHLETE_INFO_NOTE_MY_CHILD";
      case "someoneElse":
        return "NEW_ATHLETE_INFO_NOTE_SOMEONE_ELSE";
      default:
        return "";
    }
  }

  getCityState(zip: string) : string | null {
    const location = zipcodes.lookup(zip);
    if (location) {
      return location.city + ", " + location.state;
    } else {
      return null;
    }
  }
}
